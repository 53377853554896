<template>
  <div class="pictureAnalysis">
	<img src="../../assets/product/pictureAnalysis/1.png" class="headerimg" />
	<div class="serve">
		<div class="title">图像分析简介</div>
		<div class="serveinfo">
			邦伲德图像分析基于深度学习等人工智能技术和海量训练数据，提供综合性的图像智能服务，包含图像理解（解析图像中的场景、物品、人物、动物等）、图像处理（对图像进行裁剪、美化）、图像质量评估（分析图像视觉质量）等。图像分析所使用的算法，广泛应用于邦伲德内部各个产品，应用场景包含相册、信息流、社交、广告等，每天分析、处理海量图片，可以大幅提升各类产品的体验、效率。
		</div>
	</div>
	
	<div class="subfunction">
		<div class="title">产品子功能</div>
		<div class="characteristiclist">
			<div class="characteristicinfo">
				<div class="characteristicheard">
					<img src="../../assets/product/pictureAnalysis/2.png" />
					<div class="characteristictext">图像标签</div>
				</div>
				<div class="text">
					可以识别图片中的场景、物品、人物等信息，可用于相册分类、信息流内容推荐、广告推荐、视频内容理解、拍照识图等各种场景。
				</div>
			</div>
			<div class="characteristicinfo">
				<div class="characteristicheard">
					<img src="../../assets/product/pictureAnalysis/3.png" />
					<div class="characteristictext">商品识别</div>
				</div>
				<div class="text">
					涵盖25个大类、数百个细分类别，包括电商、广告场景下的常见商品，并输出商品所在坐标。
				</div>
			</div>
			<div class="characteristicinfo">
				<div class="characteristicheard">
					<img src="../../assets/product/pictureAnalysis/4.png" />
					<div class="characteristictext">车辆识别</div>
				</div>
				<div class="text">
					可以精准识别图片中的车辆坐标、品牌、车型、年款、颜色等，基本覆盖市面可见的乘用车。
				</div>
			</div>
		</div>
		
		<div class="characteristiclist">
			<div class="characteristicinfo">
				<div class="characteristicheard">
					<img src="../../assets/product/pictureAnalysis/5.png" />
					<div class="characteristictext">智能裁剪</div>
				</div>
				<div class="text">
					输出图片和指定的裁剪比例，可将不同尺寸、比例的图片，快速适配不同平台、展示位的要求。
				</div>
			</div>
			<div class="characteristicinfo">
				<div class="characteristicheard">
					<img src="../../assets/product/pictureAnalysis/6.png" />
					<div class="characteristictext">图像清晰度增强</div>
				</div>
				<div class="text">
					解决因为拍摄、滤镜、压缩导致的噪点和模糊等问题，可用于网络图片优化、相册旧照片美化等。
				</div>
			</div>
			<div class="characteristicinfo">
				<div class="characteristicheard">
					<img src="../../assets/product/pictureAnalysis/7.png" />
					<div class="characteristictext">图像质量评估</div>
				</div>
				<div class="text">
					评估输入图片在视觉上的清晰度、美观度评分，广泛应用于文章封面、视频封面、相册图片去重、低质量图片过滤等。
				</div>
			</div>
		</div>
		
		<div class="characteristic">
			<div class="title">邦伲德图像分析的特性</div>
			
			<div class="characteristiclist">
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/pictureAnalysis/8.png" />
						<div class="characteristictext">准确率高</div>
					</div>
					<div class="text">
						基于邦伲德多项行业领先的人工智能技术，支持数千个标签，可以实现一级标签平均精确率95%以上，二级标签平均精确率90%以上。 
					</div>
				</div>
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/pictureAnalysis/9.png" />
						<div class="characteristictext">能力丰富</div>
					</div>
					<div class="text">
						长期为邦伲德各业务提供智能图像技术支持，积累了丰富、可靠的系列能力，会持续提供各种图像标签、物体识别、图像处理、图像审核服务。 
					</div>
				</div>
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/pictureAnalysis/10.png" />
						<div class="characteristictext">拓展性高</div>
					</div>
					<div class="text">
						基于智能的深度学习算法，具备迁移学习能力，可以通过不断的训练使识别变得更智能，并且可以快速迭代以适应各种新场景。 
					</div>
				</div>
			</div>
		</div>
		
		<div class="adhibition">
			<div class="title">应用场景</div>
			<div class="adhibitioninfo">
				<div class="adhibitiontitle">
					商业广告精准投放
				</div>
				<div class="adhibitiontext">
					随着智能手机的全面普及，用户拍摄和存储照片的数量越来越多，大量的照片管理起来效率低下，时间成本高。图片标签可以批量读取照片的内容信息，按照场景、人物等实现相册智能分类管理。
				</div>
			</div>
			<div class="adhibitionimg">
				<img src="../../assets/product/pictureAnalysis/11.png" />
			</div>
		</div>
	</div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style scoped lang="less">
.pictureAnalysis{
	.title {
		text-align: center;
		font-size: 36px;
		font-weight: 500;
		line-height: 54px;
	}
	
	.serve{
		margin-top: 162px;
		margin-bottom: 140px;
		.serveinfo{
			font-size: 24px;
			font-weight: 500;
			color: #616B80;
			line-height: 44px;
			max-width: 80%;
			margin: auto;
			margin-top: 52px;
		}
	}
	
	.subfunction{
		.characteristiclist{
			display: flex;
			width: 80%;
			margin: auto;
			margin-top: 100px;
			margin-bottom: 140px;
			justify-content: space-between;
			.characteristicinfo{
				width: 400px;
				.characteristicheard{
					text-align: center;
					font-size: 30px;
					font-weight: 500;
					line-height: 45px;
					img{
						width: 100px;
						height: 100px;
					}
					.characteristictext{
						margin-top: 36px;
						font-size: 30px;
						font-weight: 500;
						color: #333333;
						line-height: 45px;
					}
				}
				.text{
					margin-top: 20px;
					font-size: 24px;
					font-weight: 500;
					color: #616B80;
					line-height: 40px;
				}
			}
		}
	}
	
	.characteristic{
		background-color: #F5F5F9;
		padding-top: 88px;
		padding-bottom: 91px;
	}
	
	.adhibition{
		padding-top: 88px;
		padding-bottom: 140px;
		.adhibitioninfo{
			width: 80%;
			margin: auto;
			margin-top: 80px;
			.adhibitiontitle{
				font-size: 30px;
				font-weight: 500;
				color: #333333;
				line-height: 45px;
			}
			.adhibitiontext{
				font-size: 24px;
				font-weight: 400;
				color: #616B80;
				line-height: 48px;
				margin-top: 40px;
				margin-bottom: 140px;
			}
		}
		.adhibitionimg{
			text-align: center;
			img{
				width: 1220px;
			}
		}
		
	}
}
</style>
